import React from "react";
import PropTypes from "prop-types";

const LoadingScreen = (props) => {
    return (
        <div className="w-100 h-100 align-content-center">{props.message}</div>
    );
}

LoadingScreen.propTypes = {
    message: PropTypes.string
}
LoadingScreen.defaultProps = {
    message: "Betöltés...."
}

export default LoadingScreen;