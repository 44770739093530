import React, {createContext, useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {publicFetch} from "../utils/fetch";

const AuthContext = createContext();
const {Provider} = AuthContext;

const AuthProvider = ({children}) => {
    const history = useHistory();
    const session = window.sessionStorage;

    const token = session.getItem('token');
    const userInfo = session.getItem('userInfo');
    const expiresAt = session.getItem('expiresAt');

    const [authState, setAuthState] = useState({
        token,
        expiresAt,
        userInfo: userInfo ? JSON.parse(userInfo) : {}
    });

    const setAuthInfo = useCallback(({token, userInfo, expiresAt}) => {
        session.setItem('token', token);
        session.setItem(
            'userInfo',
            JSON.stringify(userInfo)
        );
        session.setItem('expiresAt', expiresAt);

        setAuthState({
            token,
            userInfo,
            expiresAt
        });
    },[session]);

    const logout = useCallback(() => {
        session.removeItem('token');
        session.removeItem('userInfo');
        session.removeItem('expiresAt');
        setAuthState({});
        history.push('/');
    }, [history, session]);

    const isAuthenticated = useCallback(() => {
        if (!authState.token || !authState.expiresAt) {
            return false;
        }
        return (
            new Date().getTime() / 1000 < authState.expiresAt
        );
    },[authState.expiresAt, authState.token]);

    const isAdmin = () => {
        return authState.userInfo.role === 'admin';
    };

    useEffect(() => {
        const config = {
            headers: {
                'Authorization': 'Bearer ' + authState.token
            }
        }
        function refresh() {
            publicFetch.post(`/refresh`, {}, config)
                .then(resp => {
                    setAuthInfo(resp.data);
                }, reason => logout())
                .catch(error => {
                    console.log(error);
                })
        }

        if (isAuthenticated()) {
            setTimeout(refresh, 300000);
        }
    }, [authState.token, isAuthenticated, logout, setAuthInfo])

    return (
        <Provider
            value={{
                authState,
                setAuthState: authInfo => setAuthInfo(authInfo),
                logout,
                isAuthenticated,
                isAdmin
            }}
        >
            {children}
        </Provider>
    );
};


export {AuthContext, AuthProvider};