import React, {useContext} from "react";
import {BootstrapContext} from "../context/BootstrapContext";


export const getPurposeText = (purpose) => {
    return purpose === 'for_rent' ? "Kiadó" : "Eladó";
}

export const getIdText = (id) => {
    return "MH" + (id + 1000);
}

export const UserNameTag = (props) => {
    return (<>User_{props.user_id}</>);
}

export const getTargetPrice = (price) => {
    return (price / 1000000).toFixed(2) + " mFt.";
}

export const PremiseTypeText = (props) => {
    const {getBootstrapData} = useContext(BootstrapContext);

    const typeText = (i) => {
        let item = getBootstrapData('premise_type').find(x => x.id === parseInt(i));
        return item ? item.name : "????";
    }

    return (
        <>
            {typeText(props.type_id)}
        </>
    );
}

export const PropertyTypeText = (props) => {
    const {getBootstrapData} = useContext(BootstrapContext);

    const typeText = (i) => {
        let item = getBootstrapData('property_type').find(x => x.id === parseInt(i));
        return item ? item.name : "????";
    }

    return (
        <>
            {typeText(props.type_id)}
        </>
    );
}

export const RoomText = (props) => {
    const {getBootstrapData} = useContext(BootstrapContext);

    const countRooms = (premises) => {
        return premises
            .map(r => getBootstrapData('premise_type').find(pt => pt.id === r.premise_type_id))
            .filter(p => p.count_as_room).length;
    }
    const countHalfRooms = (premises) => {
        return premises
            .map(r => getBootstrapData('premise_type').find(pt => pt.id === r.premise_type_id))
            .filter(p => p.count_as_halfroom).length;
    }

    const roomCount = () => countRooms(props.rooms);
    const halfRoomCount = () => countHalfRooms(props.rooms);

    return (
        <>
            {roomCount()}
            {halfRoomCount() !== 0 && (<> + {halfRoomCount()} fél</>)}
        </>
    );
}
