import React, {Fragment, useContext, lazy, Suspense} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route
} from "react-router-dom";
import './App.css';
import Login from "./pages/Login";
import {AuthContext, AuthProvider} from "./context/AuthContext";
import LoadingScreen from "./components/LoadingScreen";
import {BootstrapProvider} from "./context/BootstrapContext";
import {ToasterProvider} from "./context/ToasterContext";
import GdprManager from "./pages/GdprManager";


const PropertyEdit = lazy(() => import("./pages/PropertyEdit"));
const PropertyList = lazy(() => import("./pages/PropertyList"));
const UserContactDetails = lazy(() => import("./pages/UserContactDetails"));

const Logout = () => {
  const {logout} = useContext(AuthContext);

  return (
      <Fragment>
        Kilépés folyamatban...
        {
          setTimeout(() => logout(), 500)
        }
      </Fragment>
  );
}

const AuthenticatedRoute = ({children, ...rest}) => {
  const authContext = useContext(AuthContext);
  return (
      <>
        {
          authContext.isAuthenticated() ? (
              <Route {...rest}>
                {children}
              </Route>
          ) : (
              <Redirect to="/login"/>
          )
        }
      </>
  );
};

function App() {
  return (
      <Router>
        <BootstrapProvider>
          <AuthProvider>
            <ToasterProvider>
              <Suspense fallback={<LoadingScreen/>}>
                <Switch>
                  <Route path="/login">
                    <Login/>
                  </Route>
                  <Route path="/logout">
                    <Logout/>
                  </Route>
                  <AuthenticatedRoute path="/edit/:property_id" component={PropertyEdit}/>
                  <AuthenticatedRoute path="/new">
                    <PropertyEdit/>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/myDatas">
                    <UserContactDetails/>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/gdpr">
                    <GdprManager/>
                  </AuthenticatedRoute>
                  <AuthenticatedRoute path="/">
                    <PropertyList/>
                  </AuthenticatedRoute>
                </Switch>
              </Suspense>
            </ToasterProvider>
          </AuthProvider>
        </BootstrapProvider>
      </Router>
  );
}

export default App;
