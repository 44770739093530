import React, {useContext, useRef, useState} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import AdminNavigation from "../containers/AdminNavigation";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {publicFetch} from "../utils/fetch";
import {AuthContext} from "../context/AuthContext";
import * as Icon from "react-bootstrap-icons";


const GdprDataProvider = (props) => {
    const inputRef = useRef();
    const {authState} = useContext(AuthContext);
    const config = {
        headers: {
            'Authorization': 'Bearer ' + authState.token
        }
    }

    const doQuery = (e) => {
        e.preventDefault();
        if (inputRef.current) {
            publicFetch
                .post("/gdpr", {contact: inputRef.current.value}, config)
                .then(resp => resp.data)
                .then(data => {
                    if (props.onSearchComplete) {
                        props.onSearchComplete(data);
                    }
                    if (inputRef.current) {
                        inputRef.current.value = "";
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title>GDPR adatszolgáltatás</Card.Title>
            </Card.Header>
            <Card.Body>
                <Form.Group controlId="formQuery">
                    <Form.Label>Elérhetőség</Form.Label>
                    <Form.Control type="text" placeholder="Elérhetőség" ref={inputRef}/>
                </Form.Group>
                <Button onClick={doQuery}>
                    <Icon.Search/> Keresés</Button>
            </Card.Body>
            <Card.Footer>

            </Card.Footer>
        </Card>
    );
}

const GdprSearchResults = (props) => {
    return (
        <Card>
            <Card.Header>
                <Card.Title>Keresési eredmények</Card.Title>
            </Card.Header>
            <Card.Body>
                .....
            </Card.Body>
        </Card>
    );
}

const GdprCleaner = (props) => {
    const inputRef = useRef();
    const {authState} = useContext(AuthContext);
    const config = {
        headers: {
            'Authorization': 'Bearer ' + authState.token
        }
    }

    const doDelete = (e) => {
        e.preventDefault();
        if (inputRef.current) {
            publicFetch
                .post("/gdpr/delete", {contact: inputRef.current.value}, config)
                .then(resp => resp.data)
                .then(data => {
                    if (inputRef.current) {
                        inputRef.current.value = "";
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title>GDPR adattörlés</Card.Title>
            </Card.Header>
            <Card.Body>
                <Form.Group controlId="formdelete">
                    <Form.Label>Elérhetőség</Form.Label>
                    <Form.Control type="text" placeholder="Elérhetőség" ref={inputRef}/>
                </Form.Group>
                <Button onClick={doDelete} variant={"danger"}>Törlés</Button>
            </Card.Body>
            <Card.Footer>

            </Card.Footer>
        </Card>
    );
}

const GdprManager = (props) => {
    const [searchResult, setSearchResult] = useState(null);


    const onSearchComplete = (data) => {
        console.log("onComplete", data);
        setSearchResult(data);
    }

    return (
        <>
            <AdminNavigation/>
            <Container fluid="true">
                <Row className="w-100">
                    <Col md="6">
                        <GdprDataProvider onSearchComplete={onSearchComplete}/>
                    </Col>
                    <Col md="6">
                        <GdprCleaner/>
                    </Col>
                </Row>
                {searchResult &&
                    <Row>
                        <Col md={12}>
                            <GdprSearchResults data={searchResult}/>
                        </Col>
                    </Row>
                }
            </Container>
        </>
    );
}

export default GdprManager;