import React, {createContext, useCallback, useState} from 'react';
import Toast from 'react-bootstrap/Toast'
import uuid from 'uuid-random';
import {getIdText} from "../utils/property_tools";

const ToasterContext = createContext();
const {Provider} = ToasterContext;

const ToasterProvider = ({children}) => {
        const [toasts, setToasts] = useState([]);

        const addToast = useCallback(({
                                          title, body, type, noDismiss = false, onClose = () => {
            }
                                      }) => {
            let newToasts = [...toasts];
            newToasts.push({
                id: uuid(),
                title: title,
                content: body,
                type: type,
                time: new Date(),
                noDismiss: noDismiss,
                onClose: onClose
            });
            setToasts(
                newToasts,
            );
        }, [toasts]);


        const addToasts = useCallback((list) => {
            let newToasts = [...toasts];
            list.map(({title, body, type, noDismiss = false, onClose = false}) => {
                return {
                    id: uuid(),
                    title: title,
                    content: body,
                    type: type,
                    time: new Date(),
                    noDismiss: noDismiss,
                    onClose: onClose
                };
            })
                .forEach(i => {
                    newToasts.push(i);
                })
            setToasts(
                newToasts,
            );
        }, [toasts]);
        const onCloseHandler = useCallback(
            (id) => {
                let current = toasts.find(t => t.id === id);
                if (current.onClose) {
                    current.onClose();
                }
                let newToasts = [...toasts];
                setToasts(newToasts.filter(t => t.id !== id));
            },
            [toasts]);

        const parseContent = (content) => {
            let property_regexp = /%PROPERTY\[(\d+)]%/;
            let newContent = content.replace(property_regexp, (matched, idx, pos, original) => {
                return "<a href=\"/edit/"+idx+"\">"+getIdText(idx)+"</a>";
            });
            return newContent;
        }

        return (
            <Provider
                value={{
                    toasts,
                    addToast,
                    addToasts
                }}>
                <>
                    <div style={{
                        position: 'fixed',
                        top: 100,
                        right: 20,
                    }}>
                        {toasts.map((t, i) => (
                            <Toast key={i} onClose={(_) => onCloseHandler(t.id)} delay={3000} autohide={!t.noDismiss}>
                                <Toast.Header>
                                    <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt=""/>
                                    <strong className="mr-auto">{t.title}</strong>
                                    <small>{t.time.getHours()}:{t.time.getMinutes()}:{t.time.getSeconds()}</small>
                                </Toast.Header>
                                <Toast.Body><span dangerouslySetInnerHTML={{__html: parseContent(t.content)}}/></Toast.Body>
                            </Toast>
                        ))}
                    </div>
                    <>
                        {children}
                    </>
                </>
            </Provider>
        );
    }
;


export {ToasterContext, ToasterProvider};