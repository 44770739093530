import React, {Component} from 'react';
import Form from 'react-bootstrap/Form'
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import {AuthContext} from "../context/AuthContext";
import {publicFetch} from "../utils/fetch";
import {Redirect} from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";


class LoginPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            login_error: null
        }
        this.submitCredentials = this.submitCredentials.bind(this);
    }

    submitCredentials = async (e) => {
        e.preventDefault();
        this.setState({
            login_error: null
        })
        try {
            const response = await publicFetch.post(
                `/auth`,
                {
                    'email_address': e.target.elements[0].value,
                    'password': e.target.elements[1].value
                }
            );
            console.log(response);
            if (response.status === 200) {
                this.context.setAuthState(response.data);
                //
                setTimeout(() => {
                    this.setState({
                        redirect: true
                    })
                }, 700);
            }
        } catch (error) {
            if (error.response !== undefined) {
                this.setState({
                    login_error: "A jelszó vagy email cím nem helyes."
                })
            } else {
                this.setState({
                    login_error: "Az authentikációs szerver nem elérhető"
                })

            }
            // setLoginLoading(false);
            // const { data } = error.response;
            // setLoginError(data.message);
            // setLoginSuccess(null);
        }
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to="/"/>
        } else {
            return (
                <Container fluid="true" className="m-3">
                    <Row className="justify-content-center">
                        <Col md={4} xs={12} >
                            <Card className="my-fieldset">
                                <Card.Header>
                                    <Card.Title className="font-bolder">
                                        Bejelentkezés</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form onSubmit={this.submitCredentials}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" placeholder="Enter email"/>
                                        </Form.Group>
                                        {this.state.login_error && <div>{this.state.login_error}</div>}
                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control type="password" placeholder="Password"/>
                                        </Form.Group>
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Container>

            );
        }
    }
}

LoginPanel.contextType = AuthContext;

export default LoginPanel;