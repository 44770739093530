import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Logo from "../pics/MH_logo_approved_final.svg"


const AdminNavigation = (props) => {
    return (
        <Navbar className="gradient shadow" expand="md">
            <Navbar.Brand href="#home">
                <img
                    src={Logo}
                    width="auto"
                    height="80"
                    className="d-inline-block align-top"
                    alt=""/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav" className="d-flex justify-content-center">
            <Nav>
                <Nav.Link href={process.env.REACT_APP_FRONTEND_URL} className="text-white">Kezdőoldal</Nav.Link>
                <Nav.Link href="/" className="text-white">Ingatlanok listázása</Nav.Link>
                <Nav.Link href="/new" className="text-white">Új hirdetés felvitele</Nav.Link>
                <Nav.Link href="/myDatas" className="text-white">Saját adatok</Nav.Link>
                <Nav.Link href="/gdpr" className="text-white">GDPR adatkezelés</Nav.Link>
                <Nav.Link href="/logout" className="text-white">Kilépés</Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}
export default AdminNavigation;