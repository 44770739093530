import React from 'react';
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import LoginPanel from "../containers/LoginPanel";

const Login = () => {
    return (
        <>
            <Container fluid="true">
                <Row>
                    <Col>
                        <LoginPanel/>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Login;